@import 'src/styles/colors';

.sidebar {
  min-height: 100vh;
  bottom: -100px;
  max-width: 350px;
  min-width: 350px;
  background-color: $color-background-primary;
  padding: 45px 27px;

  .logo-container {
    padding: 0 50px 35px;
    border-bottom: 1px solid $color-white;
  }
}
