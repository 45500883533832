@import '/src/styles/breakpoints';

.validate-session-search-form {
  .ant-space {
    width: 100%;
    gap: 31px;

    &-item {
      width: 100%;
    }
  }

  .full-width-button {
    margin-top: 29px;
  }

  .ant-input-affix-wrapper {
    padding: 0 12px;

    .ant-input-prefix {
      margin-right: 17px;
    }
  }
}
