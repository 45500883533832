@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.tenant-admins-overview-content {
  border-radius: 15px;
  background-color: $color-white;
  padding: 86px 54px;

  @media (width < $xxl) {
    padding: 43px 27px;
  }

  table {
    @media (width < $xxl) {
      margin-top: 0;
    }
  }

  h1.content-title.ant-typography {
    @media (width < $xxl) {
      margin-bottom: 20px;
    }
  }

  h2 {
    margin-bottom: 25px;
  }
}
