@import 'src/styles/colors';
@import 'src/styles/antd-overrides';
@import 'assets/fonts/fonts';

* {
  box-sizing: border-box;
  font-family: Brown, serif;
}

body {
  max-width: 100vw;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $color-background-gray;
}
