@import 'src/styles/colors';
@import 'src/styles/breakpoints';

.login-container {
  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  display: flex;
  align-items: stretch;
  justify-content: center;

  .login-info {
    padding: 30px 2%;
    border-right: 1px solid $color-gray;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      // width: 732px;
      // height: 584px;

      @media (width < $xxl) {
        // width: 390px;
        max-height: 392px;
      }
    }

    h1 {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      margin-top: 40px;
      text-align: center;

      max-width: 580px;
      color: $color-text-gray--1;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media (width < $xxl) {
        font-size: 40px;
      }
    }

    p {
      margin-top: 5px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      max-width: 470px;
      color: #616161;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -1.25px;
    }

    @media (width < $xl) {
      display: none;
    }
  }

  .login-form-container {
    height: 100vh;
    max-width: 700px;
    padding: 0 85px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/** reduce all elements to 80% */
.login-form-container {
  zoom: 0.8;
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  & p {
    font-size: 22px !important;
  }

  & .ant-input {
    font-size: 20px !important;
  }

  & .ant-checkbox-wrapper span {
    font-size: 20px !important;
  }

  & .checkbox-row a {
    font-size: 20px !important;
  }

  & .ant-btn {
    font-size: 23px !important;
  }
}
