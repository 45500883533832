@import "src/styles/colors";

.tenant-users-add-user-content {
  border-radius: 15px;
  background-color: $color-white;
  padding: 43px 53px;

  h4 {
    margin-top: 0;
    color: $color-text-black;
  }

  .submit-button {
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    padding: 13px 20px;
    height: auto;
    margin-top: 116px;
  }

  .phone-code-col {
    max-width: 115px;
  }

  .mobile-phone-col {
    max-width: calc(100% - 115px);
    width: 100%;
  }

  .submit-button {
    justify-content: center !important;
  }
}
