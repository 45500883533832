@import "src/styles/colors";
@import "src/styles/breakpoints";

.tenant-users-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tenant-users-navbar-item {
    position: relative;
    cursor: pointer;
    flex-basis: 30%;
    border-radius: 15px;
    background: $color-white;
    margin-bottom: 65px;
    padding: 22px 0;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    color: $color-text-gray;
    font-weight: 700;

    &:hover, &.tenant-users-navbar-item--active {
      &::after {
        content: "";
        position: absolute;
        width: 90%;
        border-radius: 15px 15px 0 0;
        height: 5px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-primary;
      }

      color: $color-primary;
    }

  }
}

.tenant-users-content {
  .table {
    @media (width < $xxl) {
      margin-top: 0;
      max-width: 847px;
    }
  }
}