.valid-icon-container {
  width: 17px;
  height: 18px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
