@import "src/styles/colors";

.validate-session-table-action-button {
  font-size: 11px;
  font-weight: 700;
  line-height: 11px;
  text-align: left;
  height: 31px;

  color: $color-primary;
}

.validate-session-table-action-button,
.validate-session-table-action-icon-button {
  margin-left: auto;
  margin-right: auto;
}
