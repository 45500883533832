@import "src/styles/colors";

.checkout-card {
  &-section {
    padding: 57px 79px;

    &--accent {
        background: $color-background-primary;
    }

    &-title {
      margin-top: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      color: $color-text-black;
    }
  }

  .confirm-button {
    margin-top: 82px;
    height: 39px;
  }

  .ant-spin-container {
    .ant-list-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 0;
      margin-bottom: 9px;

      font-size: 16px;
      font-weight: 400;
      line-height: 15px;
      color: #000;


      &:last-child {
        margin-top: 37px;
        font-weight: bold;
      }
    }
  }
}
