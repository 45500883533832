@import "../colors";

.ant-input,
.ant-input-affix-wrapper > input.ant-input {
  font-family: Brown, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  height: 39px;
  padding: 0 26px;
  color: $color-input-text;

  &::placeholder {
    color: $color-placeholder;
  }
}

.ant-form-item {
  margin-bottom: 30px;

  .ant-input-prefix {
    position: relative;
    top: -1px;

    + .ant-input {
      padding-left: 0;
      line-height: 0;
    }
  }
}

.ant-form-item .ant-form-item-label > label {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.ant-checkbox {
  .ant-checkbox-inner {
    width: 22.5px;
    height: 22.5px;

    &:after {
      box-sizing: content-box;
      width: calc(20px / 14 * 6);
      height: calc(20px / 14 * 6);
    }
  }
}

.ant-radio-wrapper {
  align-items: center;

  .ant-radio-inner {
    width: 24px;
    height: 24px;

    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      inset-inline-start: 25%;
      display: table;
      width: calc(24px / 14 * 6);
      height: calc(24px / 14 * 6);
      border: 2px solid #fff;
      border-top: 0;
      transform: rotate(45deg) scale(0) translate(-50%, -50%);
      opacity: 0;
      border-top: 0;
      border-inline-start: 0;

      margin-block-start: 0;
      margin-inline-start: 0;
      background-color: transparent;
      border-block-start: none;
      border-radius: 0;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      &::after {
        opacity: 1;
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
      }
    }
  }
}

.form-item-note {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: $color-note;
}

.ant-picker {
  position: relative;
  padding: 5.4px 12px;

  &-input {
    flex-direction: row-reverse;

    .anticon {
      margin-top: -2px;
    }
  }

  &-suffix {
    margin-left: 0;
    margin-right: 15px;
  }
}

.ant-picker-range {
  padding-left: 55px;

  .ant-picker-input {
    position: relative;
    top: 2px;
  }

  .calendar-icon {
    padding: 1px 2px;
  }

  .ant-picker-suffix {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
