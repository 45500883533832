@import "src/styles/colors";

.content {
  $paddingTopBottom: 70px;
  border-radius: 15px;
  background-color: $color-white;
  padding: $paddingTopBottom 55px;

  &-title {
    margin-top: -#{calc($paddingTopBottom - 46px)};

    &.ant-typography {
      color: $color-text-gray--1;
      margin-bottom: 53px;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
