$gap: 21px;

.purchase-balance-history {
  .ant-form {
    gap: $gap;
    margin-bottom: 43px;

    &-item {
      margin-right: 0;
      width:  calc(33% - $gap);
    }
  }

  button {
    margin-top: 41px;
  }

  .ant-table {
    margin-top: 41px;
  }

  .ant-picker-range {
    .ant-picker-suffix {
      left: 5px;
    }
  }

  .archived-row {
    background-color: #FFC8E2 !important;
    &:hover {
      > .ant-table-cell {
        background-color: transparent;
      }
    }
  }
}
