.purchase-search-form {
  gap: 31px;
  margin-bottom: 50px;

  > * {
    max-width: calc(33.3% - 31px);
    width: 100%;
    margin-right: 0;
  }

  .full-width-button.ant-btn:not(.ant-btn-circle) {
    margin-top: 0;
    height: 41px;
    max-width: calc(25%);
    margin-left: auto;
  }
}
