@import "src/styles/colors";

.tenant-radio-group {
  max-width: 365px;
  width: 100%;
  margin-bottom: 50px;

  .ant-radio {
    margin-right: 27px;

    &-wrapper {
      font-size: 20px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .ant-space {
    row-gap: 12px;
  }

  .ant-divider {
    margin-top: 21px;
    margin-bottom: 35px;
  }

  &-title {
    font-size: 19px;
    font-weight: 700;
    line-height: 19px;
    color: $color-primary !important;
    margin-top: 7px !important;
    margin-bottom: 0 !important;
  }
}
