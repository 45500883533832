@import "src/styles/colors";

.validate-session-table {
  $component: &;

  .ant-table-row {
    &:nth-child(odd) {
      #{$component}-action-button {
        background-color: $color-white;
      }
    }
  }

  .ant-table-cell {
    position: relative;

    .ant-space-item {
      padding: 0 25px;

      + .ant-space-item {
        &::before {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background: $color-gray;
          position: absolute;
          top: 0;
          transform: translateX(-25px);
        }
      }
    }
  }

  .hours-validated {
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    color: #8F8F8F;
  }
}

.validate-session-steps-modal {
  .ant-modal {
    max-width: 812px;
    width: 100% !important;

    &-content {
      padding: 54px 0;
      height: 454px;
    }

    &-body {
      height: 100%;
    }

    &-close {
      top: 46px;
      right: 40px;
    }
  }

  .ant-modal-close {
    border-radius: 50%;
    background: #F5F5F5CC;

    height: 36px;
    width: 36px;
  }
}
