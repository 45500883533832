@import "src/styles/colors";

.post-paid-information {
  &-row {
    margin-top: 23px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }

  &-col {
    padding: 20px 10px;
    font-size: 20px;

    &:first-child {
      border-radius: 15px 0 0 15px;
      background: $color-dark-gray;
    }

    &:last-child {
      border-radius: 0 15px 15px 0;
      background: $color-background-gray;
      color: #FF0000;
    }
  }
}
