.add-tenant-users-form {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-form-item {
    &.validation-hours {
      label {
        margin-top: 8px;
        &::before {
          display: none !important;
        }
      }
    }

    &.ant-form-item-has-success {
      + .valid-icon-container {
        opacity: 1;
      }
    }
  }

  .valid-icon-container {
    position: absolute;
    left: calc(100% - 50px);
    margin-left: 11px;
    top: 50%;
    transform: translateY(calc(-50% - 15px));
    opacity: 0;

    transition: opacity 0.25s ease-in-out;
  }

  .tenant-group {
    width: 100%;
  }

  .allow-entire-session-validation {
    margin-bottom: 0;

    > .ant-row {
      gap: 57.5px;
    }

    .ant-form-item-label {
      text-align: start;
    }

    + .form-item-note {
      margin-bottom: 30px;
    }
  }

  .ant-input-affix-wrapper {
    padding: 0 25px 0 0;
  }
}
