@import "src/styles/colors";

.menu {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  transition: 0s;

  .ant-collapse-item {
    font-size: 18px;
    margin-bottom: 38px;

    a {
      width: 100%;
    }

    &.not-collapsible {
      .ant-collapse-content {
        display: none;
      }
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    &:hover, &.ant-collapse-item-active{
      position: relative;

      .ant-collapse-header {
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: -28px;
          top: 0;
          height: 100%;
          width: 6px;
          background-color: $color-primary;
          border-radius: 0 10px 10px 0;
        }
      }

      .ant-collapse-header-text, .ant-collapse-header-text>a{
        font-weight: 700;
        color: $color-primary;
      }
    }

    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-header-text, a{
      display: flex;
      gap: 29px;
      color: $color-text-black--1;
    }

    .children-item {
      cursor: pointer;
      display: block;
      margin-top: 23px;
      text-align: center;
      color: $color-text-black--1;
      transition: color 0s;
      padding: 6px 0;

      &:hover, &.children-item--active {
        font-weight: 700;
        color: $color-primary;
        border-radius: 15px;
        background-color: rgba(255, 255, 255, 0.50);
      }
    }
  }

  .ant-collapse-item-disabled>.ant-collapse-header {
    color: $color-text-black--1;
    cursor: pointer;
  }

  .icon {
    color: $color-primary
  }
}
