.bank-transfer-purchase-form {
  .section {
    padding: 0 32px;
    margin-top: 55px;

    &-title {
      margin-left: -32px;
      margin-right: -32px;
      margin-bottom: 30px;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .ant-space {
    align-items: center;

    &-item {
      padding: 5px 15px;

      &:first-child {
        background: #E7E7E7;
      }
    }
  }

  .wrapper {
    position: relative;

    .section-currency-code {
      position: absolute;
      top: -13px;
      left: 50%;
      transform: translateX(120px);
    }
  }

  .space {
    width: 100%;
    border-radius: 15px;
    background-color: #F5F6FA;
    overflow: hidden;

    &-title {
      text-transform: uppercase;
    }

    > .ant-space-item {
      max-width: 50%;
      width: 100%;
    }

    .section-currency-code,
    &-title {
      padding: 5px 15px;
      font-size: 20px;
      font-weight: 700;
      line-height: 50px;
      color: #474747;
      text-align: center;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
    max-width: 175px;
    margin-left: auto;
    margin-right: auto;

    input {
      height: 35px;
      text-align: center;
      background-color: #fff !important;
      border-radius: 15px;

    }

    .ant-input-number {
      width: 100%;
      border-radius: 15px;

      &-handler-wrap {
        display: none;
      }
    }
  }

  .full-width-button {
    margin-top: 140px;
  }

  .ant-form-item-explain-error {
    text-align: center;
  }

  .bank-transfer-modal {
    .ant-modal-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 29px;
      color: #565656;
    }

  }
}
