$offset: 31px;

.reports-search-form {
  gap: $offset;

  .ant-form-item {
    margin-right: 0;
    max-width: calc(25% - $offset);
    width: 100%;
  }

  .submit-button {
    margin: 40px 0 25px 0;
    flex-basis: 100%;

    span {
      width: 100%;
      text-align: center;
    }
  }

  .ant-picker-suffix,
  .ant-input-prefix {
    .anticon {
      width: 24px;
    }
  }

  .calendar-icon {
    padding: 1px 2px;
  }

  .ant-picker-range {
    padding-left: 40px;
  }

  .ant-picker-suffix {
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
  }
}
