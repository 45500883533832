.bank-account-information {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;

 b {
   font-weight: 700;
 }

  > li {
    padding: 12px 0;
    margin-bottom: 9px;
  }
}

.payment-summary-footer {
  margin-top: 94px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;

  .ant-btn {
    margin-top: 10px;
    border: 1px solid #B1B1B1 !important;
    color: #0090F0;
    border-radius: 0;
    height: auto;

    font-size: 14px;
    font-weight: 400;
    line-height: 13px;
  }
}
