@import "../colors";

.ant-table {

  thead > tr > th {
    font-size: 18px;
  }

  thead > tr > th,
  tbody {
    text-align: center !important;
  }

  .column-action-space {
    column-gap: 16px;

    button {
      background: $color-background-gray;
    }
  }

  tr.ant-table-row:nth-child(odd) {
    .column-action-space {
      button {
        background: $color-white;
      }
    }
  }
}
