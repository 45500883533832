@import "src/styles/colors";
@import "src/styles/breakpoints";

.reports-export-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  margin-bottom: 27px;

  button.ant-btn:not(.ant-btn-circle) {
    background-color: $color-background-gray;
    box-shadow: none;
    color: $color-primary;
    font-size: 18px;
    font-weight: 700;
    width: 130px;
    gap: 0;

    &:hover {
      background-color: $color-background-gray;
      color: $color-primary;
    }
  }
}

.tenant-admins-overview-content {
  .full-width-button {
    height: 43px;
  }

  .table {
    @media (width < $xxl) {
      margin-top: 0;
      max-width: 903px;
    }
  }
}

