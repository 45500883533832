.full-width-button {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  height: 59px;
  align-items: center;


  &.ant-btn:not(.ant-btn-circle) {
    justify-content: center;
    line-height: 20px;
  }
}
