
@font-face {
  font-family: 'Brown';
  font-weight: 400;
  src: url("Brown/Brown-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'Brown';
  font-weight: 500;
  src: url("Brown/Brown-RegularAlt.ttf") format('truetype');
}

@font-face {
  font-family: 'Brown';
  font-weight: 700;
  src: url("Brown/Brown-BoldAlt.ttf") format('truetype');
}

