.purchase-overview {
  .section {
    $padding: 28px;

    margin-bottom: 80px;
    padding: 0 $padding;

    &-title {
      margin-left: -$padding;
      margin-right: -$padding;
    }
  }

  button.ant-btn:not(.ant-btn-circle) {
    height: 59px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .note {
    margin-top: 13px;
  }

  .ant-input-number {
    border-radius: 15px;
    text-align: right;
    margin-top: -6px;

    input {
      text-align: center;

      &::placeholder {
        text-align: center;
      }
    }

    &-handler-wrap {
      display: none;
    }
  }

  .ant-divider {
    margin-top: 79px;
    margin-bottom: 45px;
  }

  .pre-paid-information-section {
    .ant-btn {
      margin-top: 31px;
    }
  }

  .post-paid-information-section {
    .ant-btn {
      margin-top: 76px;
    }
  }
}
