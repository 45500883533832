@import "colors";
@import "antd/table";
@import "antd/modal";
@import "antd/form";
@import "antd/pagination";

button.ant-btn:not(.ant-btn-circle) {
  font-family: Brown, serif;
  display: flex;
  justify-content: flex-start;
  gap: 29px;
  line-height: 1.6;

  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: $color-primary;
    font-weight: 700;
    background-color: unset;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.ant-input-outlined {
  input {
    font-family: Brown, serif;
  }

  .ant-input-prefix {
    color: $color-placeholder;
  }
}

.ant-table-wrapper {
  font-family: Brown, serif;

  .ant-table-thead >tr>th {
    background-color: $color-primary;
    color: $color-white;
  }

  .ant-table-tbody >tr >td {
    border-bottom: unset;
  }

  .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr>td {
    border-inline-end: unset;
  }

  tr.ant-table-row {
    &:nth-child(odd) {
      background-color: $color-background-gray;
    }

    &:last-child td {
      border-bottom: unset;
    }

    td:last-child {
      border-inline-end: unset !important;
    }
  }

  .ant-table.ant-table-bordered >.ant-table-container {
    border-inline-start: unset;
    border-top: unset;
  }

  .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-141s12w).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 100%;
    background-color: $color-gray;
  }

  .ant-table-content {
    border-radius: 8px;
    overflow: hidden;
  }
}

.ant-tabs-nav {
  &::before {
    display: none;
  }

  .ant-tabs-nav-list {
    .ant-tabs-tab {
      border-radius: 15px;
      background: $color-white;
      flex-basis: 30%;
    }
  }
}

.ant-picker {
  width: 100%;
}

.ant-btn.ant-btn-circle.ant-btn-default.ant-btn-icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
}
