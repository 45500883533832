.logo {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    max-width: 272px;
    object-fit: cover;
  }
}
