.tenant-users-search-form {
  justify-content: space-between;

  .ant-form-item {
    margin-right: 0;
    flex-basis: 20%;
  }

  .submit-button {
    margin: 40px 0 25px 0;
    flex-basis: 100%;

    span {
      width: 100%;
      text-align: center;
    }
  }

  .form-section {
    > .ant-row {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
