.card-payment {
  &-section {
    margin-top: 43px;
    margin-bottom: 20px;

    &-title {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      color: #343A40;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  .expire-date {
    gap: 25px;
    align-items: center;

    &-month,
    &-year {
      max-width: 89px;

      label {
        display: block;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 13px;
        text-align: center;
      }

      input {
        text-align: center;
      }
    }

    &-divider {
      margin-top: 25px;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      color: #343A40;
    }
  }

  &-cards {
    margin-top: 19px;

    .ant-space {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    &-text {
      margin-top: 15px;
      margin-left: 10px;
      font-size: 18px;
      font-weight: 400;
      line-height: 17px;
      color: #B6B6B6;
    }
  }
}
