@import "src/styles/colors";

$columnBorderRadius: 15px;

.details-grid {
  $component: &;

  text-align: center;

  &-col {
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
  }

  &-header,
  &-footer {
    > #{$component}-col {
      &:not(:last-child) {
        border-right: 2px solid $color-gray;
      }
    }
  }

  &-header {
    > #{$component}-col {
      padding: 12px;
      background-color: $color-dark-gray;

      &:first-child {
        border-top-left-radius: $columnBorderRadius;
      }

      &:last-child {
        border-top-right-radius: $columnBorderRadius;
      }
    }
  }

  &-footer {
    > #{$component}-col {
      padding: 19px 12px;
      background: $color-background-gray;

      &:first-child {
        border-bottom-left-radius: $columnBorderRadius;
      }

      &:last-child {
        border-bottom-right-radius: $columnBorderRadius;
      }
    }

  }
}

html[dir="rtl"] {
  .details-grid {
    &-header {
      > .details-grid-col {

        &:first-child {
          border-top-right-radius: $columnBorderRadius;
          border-top-left-radius: 0;
        }

        &:last-child {
          border-top-left-radius: $columnBorderRadius;
          border-top-right-radius: 0;
        }
      }
    }
  }
}
