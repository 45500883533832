.confirm-message-step {
  padding: 0 68px;
  font-size: 20px;
  font-weight: 400;
  height: 100%;

  display: flex;
  flex-direction: column;

  .ant-col {
    font-size: 20px;
    font-weight: 700;
  }

  .ant-btn {
    margin-top: auto;
    margin-left: auto;
    padding: 0 40px;
    height: 59px;
    align-items: center;
  }
}
