@import "src/styles/colors";

.layout {
  display: flex;
  justify-content: space-between;

  &-main {
    flex-grow: 1;

    .content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
    }
  }

  &-content {
    margin: 59px 52px;
  }
}
