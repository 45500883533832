@import "src/styles/colors";
@import "src/styles/breakpoints";

.validate-session-overview {
  h1.content-title.ant-typography {
    @media (width < $xxl) {
      margin-bottom: 20px;
    }
  }

  .table {
    margin-top: 85px;

    @media (width < $xxl) {
      margin-top: 0;
      max-width: 847px;
    }
  }

  .ant-divider {
    margin: 50px 0;
  }

  .ant-pagination-options {
    display: none;
  }
}
