@import "src/styles/colors";

.select-modal {
  max-width: 812px;

  .ant-modal {
    &-content {
      padding: 54px 0;
    }

    &-header {
      padding-left: 52px;
      padding-right: 60px;
      padding-bottom: 28px;
      margin-bottom: 50px;
      border-bottom: 1px solid #ADADAD4D;
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 29px;
      color: #565656;
    }

    &-close {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #F5F5F5CC;
      top: 46px;
      right: 40px;

      svg {
        fill: #565656;
      }
    }
  }

  .ant-list {
    &-item {
      position: relative;
      padding: 28px 57px;
      border: none;

      cursor: pointer;

      font-size: 20px;
      font-weight: 700;
      line-height: 20px;

      transition: color 0.2s ease-in-out, background-color 0.2s;

      .anticon {
        display: inline-block;
        margin: 0 10px;
        position: relative;
        top: 3px;

        svg {
          width: 26px;
        }
      }

      &:hover,
      &.active {
        color: $color-primary;

        &::before {
          background-color: $color-primary;
        }
      }

      &:hover {
        background: #F7F7F7;
      }

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 100%;
        left: 0;
        top: 0;


        border-radius: 0 10px 10px 0;

        background-color: transparent;
        transition: background-color 0.2s;
      }
    }
  }
}
