.bank-transfer-search-form {
  $offset: 40px;
  gap: $offset;

  > * {
    max-width: calc(33.3% - $offset);
    width: 100%;
    margin-right: 0;
    height: 41px;
  }

  .ant-btn {
    max-width: 25%;
    margin-left: auto;
  }
}
