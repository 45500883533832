@import "src/styles/colors";

.select-validation-duration {
  height: 100%;
  padding: 0 52px;
  display: flex;
  flex-direction: column;

  .ant-select {
    width: 100%;
    height: 59px;
    align-items: center;

    color: $color-primary;

    background-color: transparent;
    text-transform: capitalize;
    border-radius: 15px;
    border: 1px solid #D9D6D6 !important;

    &-open {
      .ant-select-arrow {
        transform: rotate(180deg);
      }
    }

    &-arrow {
      transition: transform 0.15s ease-in-out;
      inset-inline-end: 36px;

      svg {
        width: 20px;

        path {
          fill: #545454;
        }
      }
    }

    &-selector {
      z-index: 1;
      background: transparent !important;
      padding: 0 70px 0 35px !important;
    }

    .ant-select-arrow {
      z-index: 0;
    }

    &-selection {
      font-size: 20px;

      &-placeholder {
        color: $color-primary;
      }

      &-placeholder,
      &-item {
        font-weight: 700;
      }
    }

    &-item {
      text-transform: capitalize;

      &-option {
        position: relative;
        border-radius: 0;
        padding: 0 35px;
        align-items: center;
        margin-top: 15px;

        &:last-child {
          margin-bottom: 15px;
        }

        &:before {
          content: '';
          width: 6px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $color-primary;
          opacity: 0;
          border-radius: 0 10px 10px 0;
        }

        &-active {
          background: linear-gradient(90deg, rgba(213, 0, 102, 0.1295) 35.9%, rgba(213, 0, 102, 0) 87.01%);

          &:before {
            opacity: 1;
          }
        }

        &-content {
          font-size: 20px;
          font-weight: 700;
          line-height: 20px;
          color: #565656;

          .note {
            margin-top: 5px;
          }
        }
      }
    }

    &-dropdown {
      padding: 0;
      border: 1px solid #D9D6D6
    }
  }

  &-dropdown-container {
    margin-top: 16px;
  }

  &-button {
    margin-top: auto;
    margin-bottom: 86px;
  }

  .rc-virtual-list-holder {
    overflow-y: auto !important;
  }
}
