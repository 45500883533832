@import "src/styles/colors";

.validate-session-steps {
  height: 100%;

  .ant-steps {
    position: relative;

    + div {
      padding-top: 31px;
      padding-bottom: 23px;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      border: 1px solid #ADADAD4D;
      top: 100%;
      left: 0;
    }

    &-item {
      $component: &;
      position: relative;
      padding-left: 52px !important;
      padding-bottom: 25px !important;
      overflow: visible;
      width: 100%;

      &:not(#{$component}-active) {
        display: none;
      }

      &-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 29px;
        color: #565656 !important;

        &::after {
          display: none;
        }
      }

      &:last-child {
        &::after {
          width: 100%;
          border-radius: 0;
        }
      }


      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        width: 50%;
        height: 10px;
        border-radius: 0 5px 5px 0;
        background: $color-primary;

        transform: translateY(-50%);
      }

      &-icon {
        display: none;
      }
    }
  }
}

html[dir="rtl"] {
  .validate-session-steps {
    .ant-steps-item {
      &-title {
        padding-inline-start: 52px;
      }
    }
  }
}
