@import "src/styles/colors";

.report-file-type-modal {
  .ant-modal {
    max-width: 749px;

    &-body {
      height: 280px;
    }
  }

  .custom-modal {
    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &-types-wrapper {
    flex-grow: 1;
  }

  &-confirm {
    margin-left: auto;
    margin-top: auto;
    padding: 0 40px;
    height: 59px;
    align-items: center;
  }

  &-types-wrapper {
    justify-content: space-between;

    .ant-space-item {
      max-width: 238px;
      width: 100%;
    }
  }

  .report-types-button {
    background-color: transparent;
    border: 1px solid #B1B1B1 !important;
    border-radius: 5px;
    width: 100%;
    height: 68px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 19px;
    color: #565656;
    padding: 14px 17px;
    box-shadow: none;

    .ant-btn-icon {
      &.icon-pdf {
        svg {
          width: 32px;
        }
      }

      svg {
        width: 25px;
      }
    }

    &:hover,
    &.active {
      background-color: $color-primary !important;
      color: $color-white !important;
      border-color: $color-primary !important;
    ;
    }
  }
}
