.confirm-modal {
  .ant-modal-confirm-btns {
    display: flex;
    flex-direction: row;

    > button {
      margin-left: auto;
    }
  }
}
html[dir="rtl"] {
  .ant-modal-close {
    left: 40px;
    right: auto;
  }
}

.custom-modal {
  &-content {
    padding-left: 52px;
    padding-right: 52px;
  }

  .ant-modal {
    &-content {
      padding: 54px 0;
    }

    &-header {
      padding-left: 52px;
      padding-right: 60px;
      padding-bottom: 28px;
      margin-bottom: 0;
      border-bottom: 1px solid #ADADAD4D;
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 29px;
      color: #565656;
    }

    &-close {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #F5F5F5CC;
      top: 46px;
      right: 40px;

      svg {
        fill: #565656;
      }
    }
  }

  .ant-list {
    &-item {
      position: relative;
      padding: 28px 57px;
      border: none;

      cursor: pointer;

      font-size: 20px;
      font-weight: 700;
      line-height: 20px;

      transition: color 0.2s ease-in-out, background-color 0.2s;

      &:hover,
      &.active {
        color: $color-primary;

        &::before {
          background-color: $color-primary;
        }
      }

      &:hover {
        background: #F7F7F7;
      }

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 100%;
        left: 0;
        right: 0;


        border-radius: 0 10px 10px 0;

        background-color: transparent;
        transition: background-color 0.2s;
      }
    }
  }
}

.bank-transfer-modal {
  &-content {
    padding-left: 52px;
    padding-right: 52px;
  }

  .ant-modal {
    &-content {
      padding: 28px 0;
    }

    &-header {
      padding: 0 40px 28px 37px;
      margin-bottom: 0;
      border-bottom: 1px solid #ADADAD4D;
    }

    &-title {
      font-size: 30px;
      font-weight: 700;
      line-height: 29px;
      color: #565656;
    }

    &-close {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: #F5F5F5CC;
      top: 17px;
      right: 15px;

      svg {
        fill: #565656;
      }
    }

    &-body {
      padding: 37px 42px 36px 42px;
      font-size: 25px;
      font-weight: 400;
      line-height: 25px;
    }

    &-footer {
      display: none;
    }
  }
}
