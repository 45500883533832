@import "src/styles/colors";

.checkout-layout {
  flex-grow: 1;

  .header {
    padding-left: 100px;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: $color-text-black;
  }

  .layout-content {
    max-width: 1067px;
    margin-left: auto;
    margin-right: auto;
  }
}
