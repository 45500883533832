@import "src/styles/colors";

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  .ant-form-item {
    width: 100%;
  }

  .ant-input-affix-wrapper {
    padding: 17px 15px;

    .ant-input-prefix {
      margin-right: 20px;
    }

  }

  .checkbox-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-form-item {
      margin: 0;
    }

    a {
      text-align: right;
      color: $color-text-link;
      flex-basis: 50%;
      display: block;
    }
  }

  button.ant-btn:not(.ant-btn-circle) {
    margin-top: 100px;
    justify-content: center;
    width: 347px;
    height: auto;
    padding: 16px 0;

    span {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }
}