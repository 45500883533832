@import "src/styles/colors";

.header {
  background-color: $color-white;
  padding: 35px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0 10px;

  .user-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .username {
      color: $color-text-black;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .actions-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .settings-dropdown-popup {
    max-width: 347px;
    width: 100%;
    padding: 40px 35px;
    border: 1px solid #E2E2E2;
    border-radius: 15px;
    background-color: $color-white;
    box-shadow: 0 4px 4px 0 #0000001A;

    h4 {
      margin-top: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
      color: $color-text-gray--1;
    }
  }

  .dropdown-popup-container {
    .ant-dropdown .ant-dropdown-menu {
      box-shadow: none;
      padding: 0;

      .ant-dropdown-menu-item {
        position: relative;
        font-size: 20px;
        font-weight: 400;
        line-height: 19px;
        padding: 21px 40px;
        border-radius: 15px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 9px;
          height: 100%;
          border-start-start-radius: 10px;
          border-end-start-radius: 10px;
          //border-radius: 10px 0 0 10px;
          background-color: transparent;
          transition: background-color 0.2s;
        }
      }

      .ant-dropdown-menu-item-active {
        background: #F7F7F7;

        &::before {
          background-color: $color-primary;
        }
      }
    }
  }

  .ant-btn {
    color: $color-text-gray;
  }
}
