@import "src/styles/colors";

.ant-pagination {
  $component: &;

  #{$component}-prev,
  #{$component}-next,
  #{$component}-jump-prev,
  #{$component}-jump-next,
  #{$component}-item {
    background: $color-background-gray;
    margin: 0;
    border-radius: 0;
    padding: 0 8px;
  }

  #{$component}-jump-prev,
  #{$component}-jump-next {
    #{$component}-item-container {
      #{$component}-item-ellipsis {
        color: $color-primary;
      }
    }
  }

  #{$component}-item {
    a {
      font-size: 16px;
      font-weight: 400;
      color: $color-primary;
    }
  }

  #{$component}-prev,
  #{$component}-next {
    color: #8E9499;

    span {
      font-size: 16px;
      font-weight: 400;

      &:hover {
        color: $color-primary;
      }
    }
  }

  #{$component}-prev,
  #{$component}-item {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  #{$component}-next,
  #{$component}-item {
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  #{$component}-item-active {
    background-color: $color-primary;

    a {
      color: #fff;
    }
  }
}
